.ContainerServ2 {
  margin-top: 20px;
  background-color: black;
  height: auto;
  padding: 20px;
  margin-top: -10px;
}

.headingServ2 {
  color: white;
  margin-left: 0;
  margin-top: -20px;
  text-align: center;
  font-weight: bold;
  font-size: 2.5rem;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px #000;
}

.servicesGridServ2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
}

.serviceItemServ2 {
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 20px;
  width: 45%;
  margin-bottom: 20px;
}

.serviceTitleServ2 {
  color: #ff6600;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px #000;
}

.serviceDescriptionServ2 {
  color: white;
  font-size: 1rem;
  line-height: 1.5;
}

.knowMoreServ2 {
  color: #ff6600;
  text-decoration: none;
  font-weight: bold;
}
