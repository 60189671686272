.containerTest2 {
  margin-top: 20px;
  background-color: rgb(0, 0, 0);
  height: auto;
  padding: 20px;
  margin-top: -1px;
}

.headTest2 {
  color: white;
  margin-left: 0;
  margin-top: -20px;
  text-align: center;
  font-weight: bold;
  font-size: 5rem;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px #000;
}

.backgroundImgTest2 {
  background: url('https://lh4.googleusercontent.com/QABOY2Xbzql8Lty40mQyCAgKcpzN6RdK4LpCKXN8uxgSa6i8a5GEBDIxmHMDhWE-0aIYuzdoqL7xpkl1NLdwPOWgbWZcAitqFLb3a07e1IF7nVT4Af6lmZCarpLziT9_Sf8rkindsvf6pgFmSOjYVw') no-repeat center center;
  background-size: cover;
  padding: 40px;
  margin-top: 20px;
  position: relative;
  height: auto;
  margin-right: 550px;
}

.contentTest2 {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
  margin-right: -620px;
  height: auto;
}

.subheadTest2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: -30px;
}

.specialCharTest2 {
  font-size: 3rem;
  color: #f39c12;
}

.titleTest2 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.descTest2 {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.nameTest2 {
  font-size: 1.2rem;
  font-weight: bold;
}

.designationTest2 {
  font-size: 1rem;
  color: #ccc;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .backgroundImgTest2 {
    margin-right: 0;
    padding: 20px;
  }

  .contentTest2 {
    margin-right: 0;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .headTest2 {
    font-size: 3rem;
    margin-top: 0;
  }

  .backgroundImgTest2 {
    margin-right: 0;
    padding: 15px;
  }

  .contentTest2 {
    padding: 10px;
    margin-right: 0;
  }

  .subheadTest2 {
    font-size: 1.5rem;
  }

  .specialCharTest2 {
    font-size: 2rem;
  }

  .titleTest2 {
    font-size: 1.2rem;
  }

  .descTest2 {
    font-size: 0.9rem;
  }

  .nameTest2 {
    font-size: 1rem;
  }

  .designationTest2 {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .headTest2 {
    font-size: 2rem;
    margin-top: 0;
  }

  .backgroundImgTest2 {
    margin-right: 0;
    padding: 10px;
  }

  .contentTest2 {
    padding: 10px;
    margin-right: 0;
  }

  .subheadTest2 {
    font-size: 1.2rem;
  }

  .specialCharTest2 {
    font-size: 1.5rem;
  }

  .titleTest2 {
    font-size: 1rem;
  }

  .descTest2 {
    font-size: 0.8rem;
  }

  .nameTest2 {
    font-size: 0.9rem;
  }

  .designationTest2 {
    font-size: 0.8rem;
  }
}
