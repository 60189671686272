.signformtc{
  padding-top: 10px;
  padding-bottom: 10px;
}
.signup-form {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    margin: 0 auto;
    background-color: #dacaca;
    border: solid black 2px;
    border-radius: 10px;
    padding: 20px;
    height: 450px;
  }
  button[type='submit'] {
    width: 400px;
    background-color: #000;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='password'] {
    width: 400px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 15px;
  }
  ::placeholder{
    font-weight: 400;
  }  
  
  button.black-button {
    background-color: #000;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  

  