.containerBus2 {
  margin-top: 50px;
  background-color: black;
  height: 240px;
  margin-top: -50px;
}

.HeadingBus2 {
  margin-left: 350px;
  color: white; /* Ensure heading is visible on black background */
}

.logoSectionBus2 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 40px;
}

.logoContainerBus2 {
  display: inline-block;
  animation: moveBus2 20s linear infinite;
}

.logoBus2 {
  width: 120px; /* Increased size */
  height: auto;
  margin-right: 30px; /* Increased gap */
  filter: brightness(0) invert(1); /* Ensure logos appear white */
  display: inline-block;
}

@keyframes moveBus2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .containerBus2 {
    margin-top: 20px;
    height: 200px;
  }

  .HeadingBus2 {
    margin-left: 20px;
    font-size: 1.5em;
  }

  .logoBus2 {
    width: 90px;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .containerBus2 {
    margin-top: -660px;
    height: 180px;
  }

  .HeadingBus2 {
    margin-left: 10px;
    font-size: 1.2em;
  }

  .logoBus2 {
    width: 70px;
    margin-right: 10px;
  }
}
