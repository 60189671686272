.container-manage25 {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -35px;
  }
  
  .fullscreen-img-manage25 {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .content-manage25 {
    width: 100%;
    max-width: 1300px;
    padding: 20px;
    text-align: center;
    background-color: black;
  }
  
  .main-heading-manage25 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 20px 0;
    color: aliceblue;
  }
  
  .security-expert-heading-manage25 {
    font-size: 2rem;
    font-weight: bold;
    color: #f39c12;
    margin: 20px 0;
  }
  
  .sub-heading-manage25 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 20px 0;
    color: aliceblue;
  }
  
  .paragraph-manage25 {
    font-size: 1.125rem;
    line-height: 1.6;
    /* color: #666; */
    color: aliceblue;
    margin: 20px 0;
  }
  
  .challenges-heading-manage25 {
    font-size: 2rem;
    font-weight: bold;
    margin: 40px 0 20px;
    color: #f39c12;
  }
  
  .key-points-manage25 {
    list-style: none;
    padding: 0;
    text-align: left;
    margin: 20px 0;
    color: aliceblue;
  }
  
  .key-points-manage25 li {
    font-size: 1.125rem;
    line-height: 1.6;
    color: aliceblue;
    margin: 10px 0;
  }
  
  .schedule-heading-manage25 {
    font-size: 2rem;
    font-weight: bold;
    margin: 40px 0 20px;
    color: #f39c12;
  }
  
  .managed-security-heading-manage25 {
    font-size: 2rem;
    font-weight: bold;
    margin: 40px 0 20px;
    color: #f39c12;
  }
  