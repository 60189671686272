.headerHeadsr25 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  height: 100px;
}

.navbar-leftHeadsr25 {
  flex: 1;
}

.nav-item-headHeadsr25 {
  text-decoration: none;
  color: #333;
  font-size: 1.2rem;
}

.navbar-rightHeadsr25 {
  flex: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-itemHeadsr25 {
  margin-left: 20px;
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

.logoHeadsr25{
  height: 100px;
}

.search-iconHeadsr25 {
  margin-left: 20px;
  cursor: pointer;
}

.sidebar-toggleHeadsr25 {
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.sidebarHeadsr25 {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1000;
}

.close-sidebarHeadsr25 {
  align-self: flex-end;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.nav-item-sidebarHeadsr25 {
  color: #fff;
  text-decoration: none;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .navbar-rightHeadsr25 {
    display: none;
  }

  .sidebar-toggleHeadsr25 {
    display: block;
    margin-top: 40px;
    color: rgb(234, 234, 234);
    
  }

  .headerHeadsr25 {
    height: 80px; /* Adjust height for mobile */
  }
}

@media only screen and (max-width: 480px) {
  .nav-item-headHeadsr25 {
    font-size: 1rem;
  }

  .nav-itemHeadsr25,
  .nav-item-sidebarHeadsr25 {
    font-size: 0.875rem;
    
  }

  .search-iconHeadsr25 {
    width: 14px;
    height: 14px;
    margin-top: -20px;
  }

  .headerHeadsr25 {
    height: 50px; /* Adjust height for smaller screens */
  }
}

/* Additional styles for other elements in the header */
/* Ensure these styles don't conflict with the header styles */



@media only screen and (min-width: 769px) {
  .sidebar-toggleHeadsr25 {
    display: none; /* Hide the toggle button on desktop */
  }
}


@media only screen and (max-width: 480px) {
  .logoHeadsr25{
    height: 100px;
    margin-top: 20px;
  }
  }


  @media only screen and (max-width: 480px) {
    .sidebar-toggleHeadsr25{
      /* height: 100px; */
      margin-top: 20px;
    }
    }



  