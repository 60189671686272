/*! CSS Used from: Embedded */
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    -webkit-overflow-scrolling: touch;
    font-family: "Roboto", sans-serif !important;
    font-weight: normal;
    font-style: normal;
}

h2,
h3 {
    margin-top: 0;
    margin-bottom: 0;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0;
    list-style: none;
}

b {
    font-weight: 500;
}

img {
    vertical-align: middle;
    border-style: none;
}

.container:after,
.container:before {
    content: " ";
    display: table;
}

a {
    text-decoration: none;
}

h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: normal;
    color: #272c37;
}

:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

@media (min-width: 768px) {
    h2 {
        font-size: 24px;
        line-height: 34px;
    }
}

.container {
    width: 100%;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.container {
    clear: both;
}

h3 {
    color: #272c37;
}

a {
    color: #1179ef;
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus {
    color: #0a5dc9;
    outline: 0;
}

img {
    max-width: 100%;
    height: auto;
}

img.blend-mode[src] {
    filter: alpha(opacity=0);
    animation: simageScroll .3s forwards;
    -webkit-animation: simageScroll .3s forwards;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.ul-iners {
    overflow: hidden;
}

.ul-iners ul {
    min-width: 45000px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.ul-iners ul li {
    position: relative;
    float: left;
}

.c_arrow {
    display: none;
}

.view-more-btn-fixed {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    display: block;
    width: 80px;
}

.view-more-btn-fixed .view-more-btn {
    display: table;
    width: 80px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #d8d8d8;
    table-layout: fixed;
    color: rgba(0, 0, 0, 0);
    font-size: 0;
    line-height: 0;
}

.view-more-btn-fixed .view-more-btn span {
    display: table-cell;
    min-width: 1px;
    height: 4px;
    cursor: default;
    vertical-align: middle;
    pointer-events: none;
    border-radius: 4px;
    border: 0;
    margin: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0);
}

.view-more-btn-fixed .view-more-btn span.active_tab {
    width: 36px;
    height: 4px;
    background-color: #1179ef;
    border-radius: 4px;
}

@media (max-width: 767px) {
    .ul-iners ul li {
        max-width: 500px;
    }
}

@media (max-width: 540px) {
    .ul-iners ul li {
        max-width: 320px;
    }
}

@media (max-width: 344px) {
    .ul-iners ul li {
        max-width: 290px;
    }
}

@media (min-width: 768px) {
    .ul-iners ul li {
        max-width: 748px;
    }

    .ul-iners ul li {
        width: 748px;
        float: left;
    }
}

@media (min-width: 992px) {
    .ul-iners ul li {
        max-width: 980px;
    }

    .c_arrow {
        display: block;
    }

    .view-more-btn-fixed {
        position: relative;
        overflow: visible;
        margin-top: 50px;
    }

    .view-more-btn-fixed span.c_arrow {
        position: absolute;
        width: 38px;
        height: 38px;
        display: inline-block;
        border-radius: 50%;
        cursor: pointer;
        color: rgba(0, 0, 0, 0);
        font-size: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: var(--sub-course-sprite) no-repeat;
    }

    .view-more-btn-fixed span.c_prev {
        background-position: -271px -284px;
        left: -58px;
    }

    .view-more-btn-fixed span.c_prev:hover {
        background-position: -213px -284px;
    }

    .view-more-btn-fixed span.c_prev.hide-btn {
        background-position: -127px -76px;
        pointer-events: none;
        cursor: default;
    }

    .view-more-btn-fixed span.c_next {
        right: -58px;
        background-position: -327px -284px;
    }

    .view-more-btn-fixed span.c_next:hover {
        background-position: -304px -76px;
    }
}

@media (min-width: 1200px) {
    .ul-iners ul li {
        max-width: 1140px;
    }
}

h2 {
    font-size: 20px;
    line-height: 1.3;
    color: #272c37;
    font-weight: 400;
    margin-top: 56px;
    letter-spacing: 1px;
}

h2 b {
    font-weight: 500;
}

h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 48px 0 0;
    line-height: 1.5;
    letter-spacing: .5px;
    color: #272c37;
    text-transform: uppercase;
}

li {
    color: #51565e;
    font-size: 14px;
    line-height: 1.43;
}

p {
    color: #51565e;
    font-size: 14px;
    line-height: 1.57;
}

.reviews div.review-card h3 {
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 992px)and (max-width: 1199px) {
    .container {
        width: 100%;
        display: inline-block;
        padding: 0 30px;
    }
}

@media (min-width: 768px) {
    h2 {
        margin-top: 64px;
        font-size: 24px;
    }
}

@media (min-width: 992px) {
    p {
        font-size: 14px;
        line-height: 1.5;
    }

    h2 {
        font-size: 32px;
        line-height: 1.33;
        letter-spacing: 1.5px;
    }

    h3 {
        font-size: 20px;
    }
}

@media (min-width: 1200px) {
    h2 {
        margin-top: 64px;
    }
}

.purdue-master div.reviews {
    background: #ebf1f8;
}

@media (min-width: 1200px) {
    .dual-master.purdue-master .reviews .ul-iners ul li .review-card {
        width: 556px;
    }
}

.revamp-pg h2 {
    text-transform: capitalize !important;
}

.revamp-pg div.reviews .ul-iners {
    padding-left: 5px;
}

.revamp-pg div.reviews .review-card {
    border: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 24px 10px 32px 10px;
}

.revamp-pg div.reviews .review-card::after {
    background: linear-gradient(90deg, #1179EF 0.41%, #27B1F8 100%);
}

@media (min-width: 992px) {
    .revamp-pg div.reviews .ul-iners ul li div.review-card {
        padding: 24px 30px 32px 30px;
    }

    .revamp-pg div.reviews .ul-iners ul li div.review-card a.profile {
        width: 110px;
        height: 110px;
    }

    .revamp-pg div.reviews .ul-iners ul li div.review-card .info {
        width: calc(100% - 110px);
        padding-right: 0;
    }

    .revamp-pg div.reviews .ul-iners ul li div.review-card .info h3 {
        font-size: 16px;
        line-height: 19px;
    }

    .revamp-pg div.reviews .ul-iners ul li div.review-card .info span {
        margin-top: 12px;
    }

    .revamp-pg div.reviews .ul-iners ul li div.review-card span.stars {
        margin-top: 16px;
    }

    .revamp-pg div.reviews .ul-iners ul li div.review-card p {
        font-size: 16px;
        line-height: 26px;
        margin-top: 40px;
    }

    .revamp-pg div.reviews.sticky_place .container {
        padding-left: 30px;
    }

    div.revamp-pg div.reviews .ul-iners ul li {
        width: 785px;
    }

    div.revamp-pg div.reviews .ul-iners ul li .review-card {
        width: 755px;
    }
}

@media (min-width: 1200px) {
    .revamp-pg div.reviews .ul-iners {
        padding-left: 3px;
    }

    .revamp-pg div.reviews.sticky_place .container {
        padding-left: 15px;
    }

    div.revamp-pg div.reviews .ul-iners ul li {
        width: 580px;
    }

    div.revamp-pg div.reviews .ul-iners ul li .review-card {
        width: 540px !important;
        border: 1px solid #e9f2ff;
        padding: 24px 25px 40px 25px;
    }

    div.revamp-pg div.reviews .ul-iners ul li .review-card .profile {
        width: 100px;
        height: 100px;
    }

    div.revamp-pg div.reviews .ul-iners ul li .review-card .info {
        padding-left: 20px;
        padding-right: 0;
    }

    div.revamp-pg div.reviews .ul-iners ul li .review-card .info h3 {
        font-size: 16px;
        line-height: 19px;
    }

    div.revamp-pg div.reviews .ul-iners ul li .review-card .info span {
        margin-top: 12px;
    }

    div.revamp-pg div.reviews .ul-iners ul li .review-card p {
        font-size: 16px;
        line-height: 26px;
    }
}

.stars {
    margin-right: 16px;
}

.reviews .stars {
    margin-top: 8px;
    margin-right: 0;
}

.star_in,
.stars {
    background: var(--dm-sprite) no-repeat;
    display: inline-block;
    width: 82px;
    height: 18px;
    background-position: -86px -57px;
    vertical-align: middle;
}

div.reviews .star_in,
div.reviews .stars {
    background-position: -86px -57px;
    text-align: left;
}

.star_in {
    background-position: -86px -35px;
    vertical-align: top;
}

div.reviews .star_in {
    background-position: -86px -35px;
}

.ul-iners ul {
    margin-bottom: 0;
}

div.revamp-pg div.reviews {
    background: #f6faff;
}

@media (min-width: 992px) {
    div.view-more-btn-fixed {
        margin-top: 32px;
    }
}

div h3 {
    text-transform: inherit !important;
}

.reviews {
    padding-bottom: 56px;
    background: #ebf1f8;
}

.reviews h2 {
    margin-bottom: 32px;
}

.reviews .container {
    padding-right: 0;
}

.reviews .review-card {
    width: 300px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    position: relative;
    padding: 32px 12px 56px 12px;
    text-align: center;
    height: 100%;
}

.reviews .review-card::after {
    width: 100%;
    content: "";
    height: 8px;
    background-color: #0a5dc9;
    background-image: linear-gradient(92deg, #128aef, #0a5dc9);
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 4px 4px;
}

.reviews .review-card .profile {
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    position: relative;
}

.reviews .review-card .profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.reviews .review-card .profile:after {
    display: inline-block;
    content: "";
    width: 26px;
    height: 26px;
    background: var(--sub-course-sprite) no-repeat;
    background-position: -18px -289px;
    position: absolute;
    bottom: 0;
    right: -6px;
}

.reviews .review-card h3 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    letter-spacing: 0;
}

.reviews .review-card h3+span {
    font-size: 14px;
    line-height: normal;
    display: inline-block;
    width: 100%;
    margin-top: 16px;
    color: #6c7480;
    font-weight: 400;
}

.reviews .review-card p {
    margin-top: 24px;
    font-size: 14px;
    line-height: 1.57;
    color: #51565e;
}

.reviews .ul-iners ul {
    margin: 0;
}

.reviews .ul-iners ul li {
    width: 320px;
}

@media (min-width: 768px) {
    .reviews .ul-iners ul li {
        width: 670px;
    }

    .reviews .review-card {
        width: 640px;
    }
}

@media (max-width: 991px) {
    .reviews {
        padding-bottom: 64px;
    }

    .reviews .container {
        width: 100%;
        padding-left: 20px;
    }
}

@media (min-width: 992px) {
    .reviews h2 {
        margin-bottom: 40px;
    }

    .reviews .review-card {
        padding: 40px 30px 56px 30px;
    }

    .reviews .review-card .profile {
        float: left;
    }

    .reviews .review-card .info {
        float: left;
        width: calc(100% - 80px);
        text-align: left;
        padding: 0 30px;
    }

    .reviews .review-card .info h3 {
        margin-top: 0;
    }

    .reviews .review-card .info h3+span {
        margin-top: 8px;
    }

    .reviews .review-card p {
        margin-top: 32px;
        display: inline-block;
        width: 100%;
        text-align: left;
        font-size: 16px;
        line-height: 1.75;
    }

    .reviews .review-card .stars {
        margin-top: 16px;
    }
}

@media (min-width: 1200px) {
    .reviews .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .reviews .ul-iners ul li {
        width: 585px;
    }

    .reviews .review-card {
        width: 555px;
    }
}

p {
    margin-top: 16px;
}

.revamp-pg div.reviews {
    background: #fff;
    padding-bottom: 56px;
}

.revamp-pg div.reviews .container {
    padding-left: 15px;
}

.revamp-pg div.reviews .review-card {
    text-align: left;
    border: 1px solid #e9f2ff;
}

.revamp-pg div.reviews .review-card a.profile {
    width: 90px;
    height: 90px;
    border-radius: 4px;
    margin: 0;
    display: inline-block;
    pointer-events: none;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgba(141, 169, 188, .2);
}

.revamp-pg div.reviews .review-card a.profile img {
    border-radius: 4px;
    height: auto;
}

.revamp-pg div.reviews .review-card a.profile::after {
    display: none;
}

.revamp-pg div.reviews .review-card .info {
    display: inline-block;
    vertical-align: top;
    padding-left: 12px;
    width: calc(100% - 90px);
    padding-top: 2px;
}

.revamp-pg div.reviews .review-card .info h3 {
    margin-top: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #272c37;
}

.revamp-pg div.reviews .review-card .info span {
    font-size: 14px;
    line-height: 16px;
    color: #6c7480;
    margin-top: 8px;
}

.revamp-pg div.reviews .review-card .info span.stars {
    margin-top: 10px;
    display: block;
    height: 14px;
    background-position: -88px -59px;
}

.revamp-pg div.reviews .review-card .info span.stars .star_in {
    margin-top: 0;
    background-position: -88px -37px;
    height: 14px;
}

.revamp-pg div.reviews .review-card p {
    margin-top: 32px;
    font-size: 14px;
    line-height: 22px;
    color: #51565e;
}

@media (min-width: 768px) {
    .revamp-pg div.reviews {
        padding-bottom: 64px;
    }

    .revamp-pg div.reviews .container {
        padding-left: 30px;
    }

    .revamp-pg div.reviews .ul-iners ul li {
        width: 369px;
    }

    .revamp-pg div.reviews .ul-iners ul li .review-card {
        width: 339px;
        padding: 24px 15px 32px 15px;
    }

    .revamp-pg div.reviews .ul-iners ul li .review-card .info {
        padding-left: 20px;
    }
}

@media (min-width: 992px)and (max-width: 1199px) {
    div.revamp-pg div.reviews .ul-iners ul li {
        width: 498px;
    }

    div.revamp-pg div.reviews .ul-iners ul li .review-card {
        width: 464px;
    }
}

@media (min-width: 992px) {
    .revamp-pg div.reviews .ul-iners ul li {
        width: 585px;
    }

    .revamp-pg div.reviews .ul-iners ul li .review-card {
        width: 555px;
    }

    .revamp-pg div.reviews div.review-card .info span.stars {
        margin-top: 28px;
    }
}

@media (min-width: 1200px) {
    .revamp-pg div.reviews .ul-iners ul li .review-card .info span.stars {
        margin-top: 16px;
    }
}

/*! CSS Used keyframes */
@-webkit-keyframes simageScroll {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    25% {
        opacity: .3;
        filter: alpha(opacity=30);
    }

    50% {
        opacity: .5;
        filter: alpha(opacity=50);
    }

    75% {
        opacity: .8;
        filter: alpha(opacity=80);
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
}

@-moz-keyframes simageScroll {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    25% {
        opacity: .3;
        filter: alpha(opacity=30);
    }

    50% {
        opacity: .5;
        filter: alpha(opacity=50);
    }

    75% {
        opacity: .8;
        filter: alpha(opacity=80);
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
}

@keyframes simageScroll {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    25% {
        opacity: .3;
        filter: alpha(opacity=30);
    }

    50% {
        opacity: .5;
        filter: alpha(opacity=50);
    }

    75% {
        opacity: .8;
        filter: alpha(opacity=80);
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
}

/*! CSS Used fontfaces */
@font-face {
    font-family: 'Roboto';
    src: url('https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff2') format('woff2'), url('https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff2') format('woff2'), url('https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('https://www.simplilearn.com/ice9/assets/fonts/Roboto-Light_v1.woff2') format('woff2'), url('https://www.simplilearn.com/ice9/assets/fonts/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('https://www.simplilearn.com/ice9/assets/fonts/Roboto-Bold_v1.woff2') format('woff2'), url('https://www.simplilearn.com/ice9/assets/fonts/Roboto-Bold_v1.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}