/* Stats.css */

.stats-container {
    padding: 24px;
    background-color: black; /* Explicitly setting to black as per the provided code */
    color: #1a202c; /* Equivalent to text-gray-800 */
    height: 350px;
    margin-bottom: -120px;

  }
  
  .stats-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .stats-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px;
    font-size: 1.5rem; /* Increased font size */
    font-weight: bold; /* Made text bold */
    color: #1a202c;
    margin-top: 50px;
  }
  
  @media (max-width: 1024px) {
    .stats-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 640px) {
    .stats-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .stats-number, .stats-label {
    display: inline-block; /* Align text in one row */
    margin: 0 4px; /* Add spacing between the number and label */
    font-size: inherit; /* Inherit font size from the parent */
    font-weight: inherit; /* Inherit font weight from the parent */
    color: rgb(232, 242, 252);
  }
  
  .stats-number{
    color: rgb(139, 139, 148);
  }