.containerCont25 {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background-color: black;
}

.headingCont25 {
  max-width: 50%;
  padding-right: 100px;
  color: aliceblue;
  margin-top: 150px;
}

.headingCont25 h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: aliceblue;
}

.headingCont25 p {
  font-size: 16px;
  margin-bottom: 20px;
  color: aliceblue;
}

.formCont25 {
  max-width: 400px;
  width: 100%;
}

.form-groupCont25 {
  margin-bottom: 15px;
}

.form-groupCont25 label {
  display: block;
  margin-bottom: 5px;
  color: aliceblue;
}

.form-groupCont25 input,
.form-groupCont25 textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.errorCont25 {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .containerCont25 {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .headingCont25 {
    max-width: 100%;
    padding-right: 0;
    margin-top: 20px;
  }

  .headingCont25 h1 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .headingCont25 p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .formCont25 {
    max-width: 100%;
    width: 100%;
  }

  button {
    width: 100%;
  }
}
