

.home-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #e3b05f no-repeat top; */
  height: 122vh; /* Updated height to use viewport height */
  background-size: cover;
  position: relative;
}

.home-banner-img {
  width: 100%;
  height: 100%; /* Updated to use parent height */
  position: absolute;
  object-fit: cover;
  object-position: top;
}


.home-banner-heading {
  font-size: 5vw; /* Updated to use viewport width for responsiveness */
  line-height: 1.2;
  color: #fff;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  margin: 10px;
  animation: fadeIn 2s ease-in-out;
  transform: perspective(500px) rotateY(5deg);
}

@media only screen and (min-width: 970px) {
  .home-banner {
    margin: 0;
  }

  .home-banner-heading {
    font-size: 3vw; /* Adjusted for larger screens */
    line-height: 1.2;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
  }
}

@media only screen and (max-width: 600px) {
  .home-banner-heading {
    font-size: 8vw; /* Adjusted for smaller screens */
    line-height: 1.2;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

* {
  outline: 0;
  -webkit-touch-callout: none !important;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}



/* .home-banner-img {
  width: 100%;
  height: 100%; 
  position: absolute;
  object-fit: cover;
  object-position: top;
} */

@media only screen and (max-width: 600px) {
  .home-banner-img {
    height: 50vh; /* Adjusted height for mobile devices */
    width: 450px;
    margin-top: -500px;
  }
  .home-banner-heading{
margin-top: -250px;
  }
}
