/* FooterStyle.css */

.footer-foot25 {
    padding: 24px;
    background-color: #1b1616;
    color: #ffffff;
  
    
  }
  
  .footer-container-foot25 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    max-width: 1200px;
    margin: 0 auto;
  }

  
  @media (min-width: 640px) {
    .footer-container-foot25 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 768px) {
    .footer-container-foot25 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .footer-section-foot25 {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .footer-heading-foot25 {
    font-weight: 500;
    color: #f39c12;
    margin-top: -5px;
  }
  
  .footer-links-foot25 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1rem;
    color: azure;
    font-weight: 500;
    color: azure;
    margin-top: 20px;
    

  }
  
  .about-text-foot25 {
    font-weight: bold;
    color: aliceblue;
    font-size: large;
    margin-top: -10px;
  }
  

  .footer-linksk-foot25{
    font-weight: bold;
    color: aliceblue;
    font-size: large;
    margin-top: -10px;
  }
  .add{
    color: #ffffff;
  }

.linksk{
    margin-left: 50px;
}

  .footer-links-foot25 a {
    color: inherit;
    text-decoration: none;
    font-size: 1.125rem; /* Slightly larger font size */
    font-weight: bold; /* Bold text */
  }
  
  .footer-links-foot25 a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom-foot25 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    font-size: 0.875rem;
    color: #4a5568;
  }
  
  .footer-bottom-foot25 span {
    text-align: center;
  }
  