.aboutus-container-about25 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.aboutus-section-about25 {
  width: 100%;
  position: relative; /* Ensure the heading is properly positioned */
}

.aboutus-fullscreen-img-about25 {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  margin-top: -5px;
}

.aboutus-full-width-text-about25 {
  width: 100%;
  padding: 20px;
  background-color: rgb(32, 32, 32);
  color: #c3c2c2;
}

.aboutus-content-about25 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(32, 32, 32);
}

.fixed-left-img-about25 {
  flex: 1;
  width: auto;
  height: 100%;
  object-fit: cover;
  position: sticky;
  left: 0;
}

.aboutus-text-content-about25 {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 20px;
}

.aboutus-text-left-about25 {
  flex: 2;
  padding: 20px;
}

.aboutus-text-top-about25, .aboutus-text-bottom-about25 {
  flex: 1;
  padding: 20px;
}

.aboutus-heading1-about25 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fdf8f8;
  margin-bottom: 20px;
}

.aboutus-heading2-about25 {
  font-size: 1.75rem;
  font-weight: bold;
  color: #ff6600;
  margin-bottom: 20px;
}

.aboutus-paragraph-about25 {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #c3c2c2;
}

.aboutus-side-img-about25 {
  flex: 1;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.aboutus-heading-3d {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff; /* Text color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Text shadow for 3D effect */
  z-index: 10; /* Ensure it's above the image */
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .aboutus-heading-3d {
    font-size: 2.5rem;
  }

  .aboutus-paragraph-about25 {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .aboutus-content-about25 {
    flex-direction: column;
  }

  .fixed-left-img-about25 {
    position: static;
    width: 100%;
    height: auto;
  }

  .aboutus-text-content-about25 {
    flex-direction: column;
  }

  .aboutus-side-img-about25 {
    width: 100%;
    height: auto;
  }

  .aboutus-heading-3d {
    font-size: 2rem;
  }

  .aboutus-paragraph-about25 {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .aboutus-fullscreen-img-about25 {
    height: 40vh;
    margin-top: 25px;
  }

  .aboutus-heading-3d {
    font-size: 1.5rem;
  }

  .aboutus-paragraph-about25 {
    font-size: 0.8rem;
  }

  .aboutus-heading1-about25 {
    font-size: 1.2rem;
  }

  .aboutus-heading2-about25 {
    font-size: 1.5rem;
  }

  .aboutus-content-about25 {
    padding: 10px;
  }

  .aboutus-text-content-about25, .aboutus-text-left-about25, .aboutus-text-top-about25, .aboutus-text-bottom-about25 {
    padding: 10px;
  }
}
